const aeJSON = {
  AUTH_SIGN_UP_CARD_TITLE: 'Inscrever-se',
  AUTH_SIGN_UP_CARD_SUB_TITLE:
    'Preencha os campos abaixo para criar uma conta.',
  AUTH_SIGN_UP_CARD_GOOGLE_BUTTON: 'Inscreva-se no Google',
  AUTH_SIGN_UP_CARD_OR_SEPARATOR: 'ou',
  AUTH_SIGN_UP_CARD_NAME_FIELD_LABEL: 'Nome',
  AUTH_SIGN_UP_CARD_NAME_FIELD_ERROR: 'O campo nome é obrigatório',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_LABEL: 'E-mail',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_REQUIRED: 'O campo e-mail é obrigatório',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_INVALID:
    'O e-mail fornecido deve ser um endereço de e-mail válido',
  AUTH_SIGN_UP_CARD_PASS_FIELD_LABEL: 'Senha',
  AUTH_SIGN_UP_CARD_PASS_FIELD_ERROR: 'O campo de senha é necessária',
  AUTH_SIGN_UP_CARD_TERMS_AGREE_STATEMENT: 'Eu aceito o',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS: 'termos e Condições',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS_ERROR:
    'Você deve concordar com nossos termos e condições',
  AUTH_SIGN_UP_CARD_BUTTON: 'Inscrever-se',
  AUTH_SIGN_UP_CARD_HAVE_ACCOUNT: 'já tem uma conta?',
  AUTH_SIGN_UP_CARD_LOGIN_LINK: 'Faça login aqui',
  AUTH_SIGN_UP_SIDEBAR_TITLE: 'Crie relatórios de inspeção digitais',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_1: 'Painel de gerenciamento de inspeção',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_2: 'Aplicativo de inspeção fácil de usar',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_3:
    'Relatórios de inspeção digital voltados para dispositivos móveis',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_4:
    'Experimente 10 relatórios gratuitos por mês',
  AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL: 'E-mail',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED: 'O campo e-mail é obrigatório',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID:
    'O e-mail fornecido deve ser um endereço de e-mail válido',
  AUTH_LOGIN_CARD_PASS_FIELD_LABEL: 'Senha',
  AUTH_LOGIN_CARD_PASS_FIELD_ERROR: 'O campo de senha é necessária',
  AUTH_LOGIN_CARD_GOOGLE_BUTTON: 'Faça login no Google',
  AUTH_LOGIN_CARD_BUTTON: 'Entrar',
  AUTH_LOGIN_CARD_FORGOT_PASSWORD: 'Esqueceu sua senha?',
  AUTH_LOGIN_CARD_RESET_PASSWORD_LINK: 'Redefinir senha',
  AUTH_LOGIN_CARD_NO_ACCOUNT: 'Não tem uma conta?',
  AUTH_LOGIN_CARD_REGISTER_LINK: 'Registro',
  AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS: 'senha ou email incorretos',
  USERS_LIST_PAGE_HEADER_TITLE: 'Usuários',
  USERS_LIST_PAGE_HEADER_CAPTION: 'Gerencie seus usuários',
  USERS_LIST_EMPTY_MESSAGE: 'Nenhum usuário',
  USERS_LIST_PAGE_HEADER_CREATE_BUTTON: 'Criar novo usuário',
  USERS_LIST_DELETE_USER_CONFIRMATION_MESSAGE:
    'Você realmente deseja excluir este usuário?',
  USERS_LIST_DELETE_USER_BUTTON_TOOLTIP: 'Excluir',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_TITLE: 'usuário criado',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_PASSWORD:
    'O usuário foi criado com a senha:',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL:
    'Também foi enviado um e-mail de convite ao usuário em',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL_EXTENDED:
    ', eles podem usar o link nesse e-mail para definir sua própria senha.',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_CAPTION:
    'Use esta senha para fazer login',
  USERS_LIST_CREATE_USER_DIALOG_TITLE: 'Criar novo usuário',
  USERS_LIST_CREATE_USER_DIALOG_CAPTION: 'Adicionar um novo usuário',
  USERS_LIST_CREATE_USER_DIALOG_CREATE_BUTTON: 'Criar usuário',
  REPORT_DETAILS_HEADER_TITLE: 'Detalhes do relatório',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'baixar PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'BaixarXLSX',
  REPORT_DETAILS_NO_ANSWER: 'Sem resposta',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Responsabilidade',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'Esta seção mostra quem executou o relatório',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Verificação',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Todos os dados verificados com Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unidade',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspeção',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE: 'Tem certeza?',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_SUBTITLE:
    'Isso também excluirá todos os itens desta categoria.',
  COPIED: 'Copiado com sucesso...',
  NO_ACTIVITY_MESSAGE:
    'Os detalhes da atividade ainda não estão disponíveis...',
  COMMENT: 'COMENTE',
  configurations: 'Configurações',
  REPORT_HOOK: 'Configurações de gancho de relatório',
  REPORT_PDF: 'Relatar configurações de PDF',
  DISABLED: 'Desabilitado',
  ENABLED: 'Habilitado',
  new_configuration: 'nova configuração',
  Key: 'Chave',
  Title: 'Título',
  Actions: 'Ações',
  Clients: 'Clientes',
  Value: 'Valor',
  Edit: 'Editar',
  Cancel: 'Cancelar',
  Save: 'Salvar',
  Saved: 'Salvou',
  OPTIONS: 'Opções',
  FORGET_PASSWORD: 'Esqueça a senha',
  UPDATE_PASSWORD_TITLE: 'Atualizar senha',
  CONFIRM_PASSWORD_VALIDATION:
    'Por favor, insira a mesma senha nos campos Senha e Confirmar senha',
  PASSWORD_VALIDATION: 'O campo de senha é necessária',
  TITLE_VALIDATION: 'O campo título é obrigatório',
  PASSWORD_UPDATED: 'A senha foi atualizada com sucesso, tente fazer login',
  ERROR: 'Algo deu errado',
  PASSWORD: 'Senha',
  CONFIRM_PASSWORD: 'Confirme sua senha',
  UPDATE_PASSWORD: 'Atualizar senha',
  UPDATE_USER: 'Atualizar usuário',
  INVALID_EMAIL: 'E-mail inválido',
  UPDATE_PASSWORD_TOGGEL: 'Atualizar senha',
  NAME_VALIDATION: 'O campo nome é obrigatório',
  EMAIL_VALIDATION: 'O campo e-mail é obrigatório',
  MESSAGE_VALIDATION: 'O campo mensagem é de preenchimento obrigatório',
  SHOULD_VALID_EMAIL:
    'O e-mail fornecido deve ser um endereço de e-mail válido',
  IMAGE_VALIDATION: 'O campo da imagem é obrigatório',
  NAME_PLACEHOLDER: 'Nome...',
  NAME_LABEL: 'Nome',
  EMAIL_PLACEHOLDER: 'E-mail...',
  EMAIL_LABEL: 'E-mail',
  MESSAGE_LABEL: 'Mensagem',
  CLIENT_IMAGE_LABEL: 'Imagem',
  PASSWORD_PLACEHOLDER: 'Senha...',
  PASSWORD_LABEL: 'Senha',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirme sua senha...',
  CONFIRM_PASSWORD_LABEL: 'Confirme sua senha',
  SAVE_USER: 'Salvar usuário',
  UPDATE_PROFILE: 'Atualizar perfil',
  FLAGGED: 'Sinalizado',
  REPORT_DETAILS: 'Detalhes do relatório',
  REMOVE_FLAG: 'Remover bandeira',
  ADD_FLAG: 'Adicionar bandeira',
  CLIENT_REPORTS: 'Relatórios de clientes',
  CLIENTS: 'Clientes',
  REPORTS: 'Relatórios',
  CHECKS: 'Verificações',
  UNITS: 'Unidades',
  DELETE: 'Excluir',
  DELETED: 'Apagado com sucesso',
  UNIT_DELETE_ALERT_TITLE: 'Tem certeza de que deseja excluir esta unidade?',
  NO: 'Não',
  YES: 'Sim',
  NOT_SURE: 'Não tenho certeza',
  FORGET_PASSWORD_Q: 'Esqueceu a senha?',
  RESET_PASSWORD: 'Redefinir senha',
  RESET_PASSWORD_TITLE: 'Recuperar senha',
  RESET_PASSWORD_SUB_TITLE: 'Insira seu e-mail para redefinir sua senha',
  UPDATE_PASSWORD_SUB_TITLE: 'Digite sua senha para atualizar sua senha',
  SEND_NEW_PASSWORD: 'envie-me uma nova senha',
  TRY_SIGN: 'Quer tentar fazer login novamente?',
  CLICK_HERE: 'Clique aqui',
  PLATFORM_TEMPLATE_INFO:
    'Os modelos de plataforma não podem ser modificados até que estejam conectados a uma unidade',
  RESET_PASSWORD_INSTRUCTIONS:
    'As instruções de redefinição de senha foram enviadas para seu e-mail',
  CHECK_EMAIL_INSTRUCTIONS: 'Verifique seu e-mail para obter mais instruções',
  CONTINUE_LOGIN: 'Continuar para fazer login',
  TITLE: 'Título',
  REFERENCES: 'Inspeções',
  REFERENCE: 'Inspeção',
  GENERATE_REFERENCE: 'Criar nova inspeção',
  LINK: 'Link',
  NO_REPORTS: 'Nenhum relatório criado ainda',
  FREE_TRIAL: 'Comece seu teste gratuito hoje',
  EMAIL_VERIFICATION: 'Verifique seu e-mail',
  SEND_EMAIL_TO: 'Enviamos um e-mail para',
  EMAIL_VERIFICATION_CONTINUE:
    'Para continuar, verifique seu e-mail e clique no link para verificar sua conta.',
  NOT_RECEIVE_EMAIL: 'Não recebeu o e-mail?',
  RESEND_EMAIL: 'Reenvie o e-mail',
  EMAIL_VERIFICATION_RESEND_ERROR: 'Falha ao enviar o e-mail',
  TERMS_VALIDATION: 'Você deve concordar com nossos termos e condições',
  REGISTRATION_LAST_STEP: 'Mais um passo e pronto!',
  KNOW_MORE_INFO: 'Por favor, informe-nos o nome da sua empresa',
  COMPANY_VALIDATION: 'O campo empresa é obrigatório',
  COMPANY_LABEL: 'Nome da empresa',
  COMPANY: 'Empresa',
  SIGN_UP_HERE: 'Inscrever-se',
  GETTING_START: 'Iniciar',
  THANK_YOU: 'Obrigado!',
  ON_BOARD: 'Estamos entusiasmados por ter você a bordo.',
  UPDATE: 'Atualizar',
  SAVED: 'Salvo com sucesso',
  SENT: 'Enviado com sucesso',
  UPDATE_COMPANY_HEADER: 'Detalhes da Companhia',
  UPDATE_COMPANY_INFO: 'Você deve definir o nome da empresa para continuar',
  SEND_INSTRUCTIONS_EMAIL: 'Enviar e-mail de instruções',
  TOKEN_INSTRUCTION_EMAIL_TITLE: 'Enviar e-mail com instruções do relatório',
  INSTRUCTION_EMAIL: 'E-mail',
  RESET_PASSWORD_SUCCESS:
    'Obrigado, se o endereço de e-mail inserido estiver correto, você receberá um e-mail em breve com instruções sobre como redefinir sua senha',
  UPDATE_CLIENT: 'Detalhes da Companhia',
  UPDATE_CLIENT_PAGE_TITLE:
    'Utilize esta página para atualizar os dados da sua empresa.',
  UPDATE_CLIENT_CARD_TITLE: 'Detalhes da Companhia',
  COMPANY_NAME_PLACEHOLDER: 'Atualizar nome da empresa',
  COMPANY_NAME_LABEL: 'Nome da empresa',
  SAVE_CLIENT: 'Salvar cliente',
  'Yes/No': 'Sim não',
  MEDIA: 'meios de comunicação',
  TEXT: 'Texto',
  NUMBER: 'Número',
  RADIO_BUTTON: 'Múltipla escolha',
  CHECK_BOX: 'Caixas de seleção',
  SELECT_OPTIONS_HOLDER: 'entradas separadas com chave de retorno',
  SELECT_OPTIONS: 'Opções',
  SELECT_OPTIONS_HELPER:
    'Liste as opções no campo de texto; use Enter para separar as opções',
  REGISTER_HTML_TITLE: 'Verifique primeiro - Inscreva-se',
  FILTER: 'Filtro',
  FLAGGED_all: 'Mostre tudo',
  FLAGGED_true: 'Apenas relatórios sinalizados',
  MANAGE_API_KEY_SUBHEADER: 'Gerencie suas chaves de API',
  API_KEY_MODAL_HEADER: 'Gerar chave de API',
  API_KEY_MODAL_SUBHEADER: 'Insira um nome para identificar esta chave',
  CREATED_API_KEY_MODAL_HEADER: 'Chave criada',
  CREATED_API_KEY_MODAL_SUBHEADER:
    'Guarde-o em um local seguro, pois não pode ser recuperado. Verifique a documentação do desenvolvedor para saber como usá-lo!',
  NAME: 'Nome',
  DOWNLOAD_XLSX: 'Baixar XLSX',
  SUBSCRIBE_TITLE: 'Escolha seu plano',
  SUBSCRIBE_BODY:
    'Preços simples e transparentes que crescem com você. Experimente qualquer plano gratuitamente por 30 dias.',
  SUBSCRIBE_BODY2: 'Preço anual (economize 15%)',
  SUBSCRIBE: 'Se inscrever',
  PAYMENT_SUCCESS_BUTTON: 'iniciar',
  TRY_AGAIN: 'Tente novamente',
  PAYMENT_SUCCESS_TITLE: 'Obrigado',
  PAYMENT_SUCCESS_BODY:
    'Suas informações foram salvas com sucesso. Estamos entusiasmados em ter você a bordo!',
  PAYMENT_FAILURE_TITLE: 'Algo deu errado',
  PAYMENT_FAILURE_BODY:
    'Seu pagamento pode falhar devido ao saldo do seu cartão ou outro problema. Entre em contato com o atendimento ao cliente para obter mais esclarecimentos',
  SUBSCRIPTION_TITLE: 'Gerenciar assinaturas',
  SUBSCRIPTION_DESCRIPTION: 'GERENCIE SUA ASSINATURA',
  SUBSCRIPTION_CARD_HEADER: 'Informações de assinatura',
  SUBSCRIPTION_CARD_SUB_HEADER:
    'Gerencie detalhes relacionados aos seus endereços de e-mail associados',
  SUBSCRIPTION_PLAN: 'Plano',
  SUBSCRIPTION_CARD: 'Cartão de crédito',
  SUBSCRIPTION_BILLED_WITH: 'Faturado com Mastercard ••••',
  MANAGE_SUBSCRIPTION: 'Gerenciar assinatura',
  SUBSCRIPTION_ACTIVE: 'Ativo',
  SUBSCRIPTION_IN_ACTIVE: 'Inativo',
  SUBSCRIPTION_END: 'DATA FINAL',
  PRICING_PLANS: 'Planos de preços',
  CURRENCY: '€',
  MONTH: 'mês',
  YEAR: 'o',
  ENTERPRISE_TITLE: 'Entrar em contato',
  ENTERPRISE_NAME: 'Platina',
  ENTERPRISE_DESCRIPTION: 'Criado para clientes corporativos',
  ENTERPRISE_FEATURE1: 'Unidades ilimitadas',
  ENTERPRISE_FEATURE2: 'Logon único',
  ENTERPRISE_FEATURE3: 'SLA de alta disponibilidade',
  ENTERPRISE_FEATURE4: 'Integrações personalizadas',
  ENTERPRISE_FEATURE5: 'Suporte e treinamento de integração',
  ENTERPRISE_FEATURE6: 'Todos os recursos Ouro',
  EDIT_ITEM: 'Atualizar',
  UPDATE_CHECKLIST_ITEM: 'Atualizar itens da lista de verificação',
  EDIT: 'Editar',
  N0_CLIENTS_ACTIVITIES: 'Nenhuma atividade de cliente',
  CREATION_DATE: 'Data de criação',
  ACTIVITY_ACTION: 'Ação',
  ACTIVITY_MODULE: 'Módulo',
  CLIENT_NAME: 'Nome',
  CLIENT_LABEL: 'Cliente',
  CLIENT_ACTIVITY_SEARCH_PLACEHOLDER: 'Selecione o cliente',
  CLIENT_ACTIVITY_TITLE: 'Atividades de clientes',
  MULTI_MEDIA: 'Multimídia',
  CONDITION: 'Doença',
  UPLOAD: 'Selecione uma imagem para carregar',
  BRONZE: 'Bronze',
  GOLD: 'Ouro',
  ENTERPRISE: 'Empreendimento',
  NONE: 'Nenhum',
  HISTORY: 'História',
  CHECKLIST_ITEM_DRILL_DOWN_DESCRIPTION:
    'Abaixo está o histórico de todas as inspeções desta unidade. Clique em cada data para carregar o relatório correspondente.',
  REPORTS_TO_DATE: 'Relatórios até o momento',
  FLAGGED_REPORTS: 'Relatórios sinalizados',
  ACTIVE_UNITS: 'Unidades Ativas',
  RECENT_REPORTS: 'Relatórios recentes',
  RECENT_UNITS: 'Unidades adicionadas recentemente',
  INSPECTOR: 'Inspetor',
  DATE: 'Data',
  REPORTS_COUNT: 'Relatórios',
  UNIT: 'Unidade',
  VIEW_ALL: 'Ver tudo',
  ISSUE_PRIORITY: 'Prioridade de emissão',
  ISSUE_TITLE: 'Prioridade de emissão',
  ISSUE_DESCRIPTION: 'Descrição',
  ISSUE_ID: 'EU IA',
  ISSUE_REPORTED: 'Reportado',
  ISSUES: 'Sinalizado',
  ISSUE_GALLERY: 'Galeria',
  ISSUE_DETAILS: 'Detalhes do problema',
  SHARING_REPORT: 'Compartilhar',
  LOGGED_IN_FIRST: 'Você deve estar logado',
  WELCOME_MESSAGE: 'Bem-vindo,',
  YOUR_STATISTICS_MESSAGE: 'Suas estatísticas para hoje,',
  ISSUES_HEADER: 'Sinalizado',
  ISSUES_SUBHEADER: 'Gerenciar problemas encontrados durante a inspeção',
  ISSUES_PRIORITY: 'PRIORIDADE',
  UPPERCASE_TITLE: 'TÍTULO',
  STATUS: 'STATUS',
  UPPERCASE_UNIT: 'UNIDADE',
  DESCRIPTION: 'DESCRIÇÃO',
  IMAGES: 'IMAGENS',
  REPORTED: 'RELATADO',
  DEACTIVATED: 'Desativado',
  DEACTIVATE: 'Desativar',
  ACTIVATE: 'Ativar',
  SHOW_ACTIVE_CLIENTS: 'Mostrar clientes ativos',
  SHOW_DEACTIVATED_CLIENTS: 'Mostrar clientes desativados',
  CLIENT_USERS: 'Usuários clientes',
  CO_SIGN: 'Co-assinar',
  CO_SIGN_HERE: 'Assine aqui',
  SIGN_MODAL_TITLE: 'Relatório de co-assinatura',
  SIGN_MODAL_DESCRIPTION:
    'Use esta janela de diálogo para assinar o relatório de inspeção.',
  NAME_HOLDER: 'Adicionar nome',
  NAME_REQUIRED: 'O campo nome é obrigatório',
  SIGNATURE_IMAGE_URL: 'Assinatura',
  SIGNATURE_REQUIRED: 'O campo de assinatura é obrigatório',
  SIGN_BUTTON: 'Enviar',
  UPDATE_PASSWORD_INFO: 'Quer atualizar sua senha?',
  UPDATE_PASSWORD_LINK: 'Atualizar senha',
  UPDATE_USER_PASSWORD: 'Alterar a senha',
  UPDATE_USER_PASSWORD_CARD_TITLE: 'Informação do usuário',
  OLD_PASSWORD_LABEL: 'Senha atual',
  SAVE_USER_PASSWORD: 'Salvar',
  OLD_PASSWORD_PLACEHOLDER: 'Senha atual...',
  COPY_REPORT_TOKEN: 'Copiar token de relatório',
  QR_CODE: 'Código QR',
  QR_CODE_MODAL_TITLE: 'Link de inspeção QR',
  DOWNLOAD: 'Download',
  DOWNLOADED: 'Baixado com sucesso',
  DEVELOPMENT: 'Desenvolvimento',
  UNIT_FORM_DESCRIPTION_PLACEHOLDER: 'Insira uma descrição aqui.',
  UNIT_FORM_TITLE_PLACEHOLDER: 'Insira um título aqui.',
  UNIT_FORM_DESCRIPTION_CAPTION:
    'Essas informações serão vistas pelo usuário final quando ele iniciar o relatório, portanto, certifique-se de que esta descrição o ajude a criar o relatório da maneira que for melhor para o seu negócio.',
  CREATE_USER_NAME_PLACEHOLDER: 'Digite o nome aqui',
  CREATE_USER_EMAIL_PLACEHOLDER: 'Digite o e-mail aqui',
  SAVE: 'Salvar',
  CREATE: 'Criar',
  UPLOAD_CLIENT_LOGO: 'Logotipo',
  COPY: 'cópia de',
  ROLE: 'Papel',
  ROLE_LABEL: 'Selecione a função do usuário',
  ROLE_client: 'Administrador do cliente',
  ROLE_client_manager: 'Gerente de clientes',
  ROLE_client_contributor: 'Colaborador do cliente',
  USERS_LIST_EDIT_USER_BUTTON_TOOLTIP: 'Atualizar usuário',
  USERS_LIST_UPDATE_USER_DIALOG_TITLE: 'Atualizar informações do usuário',
  USERS_LIST_UPDATE_USER_DIALOG_CAPTION: 'Atualizar usuário',
  USERS_LIST_UPDATE_USER_DIALOG_CREATE_BUTTON: 'Atualizar usuário',
  WEBHOOK_SCREEN_LIST_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_LIST_CAPTION: 'Gerencie seus webhooks aqui',
  WEBHOOK_SCREEN_LIST_CREATE_BUTTON: 'Adicionar webhook',
  REPORT_CREATED: 'Relatório',
  ISSUE_CREATED: 'Emitir',
  COUNTER_SIGNATURE_CREATED: 'Contra-assinatura',
  TYPE: 'TIPO',
  URL: 'URL',
  MODIFIED: 'MODIFICADO',
  NO_WEBHOOKS_ADDED: 'Nenhum webhooks adicionado ainda.',
  ACTION: 'AÇÃO',
  EDIT_WEBHOOK: 'Editar',
  WEBHOOK_DETAILS: 'Detalhes',
  WEBHOOK_SCREEN_SAVE_TITLE: 'Adicionar webhook',
  WEBHOOK_SCREEN_SAVE_CAPTION:
    'Selecione sobre qual tipo de evento você gostaria de ser notificado e adicione o URL do seu webhook.',
  HOOK_TYPE_REQUIRED: 'O campo tipo é obrigatório',
  HOOK_URL_REQUIRED: 'O campo URL é obrigatório',
  ADD_WEBHOOK: 'Adicionar webhook',
  WEBHOOK_TYPE_LABEL: 'Tipo',
  WEBHOOK_TYPE_PLACEHOLDER: 'Tipo',
  WEBHOOK_URL_PLACEHOLDER: 'URL',
  WEBHOOK_URL_LABEL: 'URL',
  WEBHOOK_SCREEN_DETAILS_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_DETAILS_CAPTION: 'Organização / Webhooks',
  HOOK_DATE: 'DATA',
  WEBHOOK_RETRY: 'Tentar novamente',
  SUCCESS: 'Sucesso',
  FAILED: 'Fracassado',
  REQUEST_DETAIL_MODAL_HEADER: 'Pedir detalhes',
  DETAILS: 'Detalhes',
  RESPONSE: 'RESPOSTA',
  BODY: 'Corpo',
  ACTIONS: 'Ações',
  INVALID_MAGIC_LINK: 'O link mágico pode ser inválido ou expirado',
  URL_VALIDATION: 'Insira um URL válido',
  UNIT_FORM_REDIRECT_URL_PLACEHOLDER:
    'Insira um URL de redirecionamento de inspeção aqui.',
  UNIT_FORM_REDIRECT_URL_LABEL:
    'Insira um URL de redirecionamento de inspeção aqui.',
  ID_NAME: 'Nome de identificação',
  CREATED_AT: 'Criada',
  OPEN: 'Abrir',
  NEW_CHECKLIST_TITLE: 'Nova lista de verificação',
  UNTITLED_CHECKLIST: 'Lista de verificação sem título',
  UNTITLED_CHECKLIST_FROM_TEMPLATE:
    'Lista de verificação sem título (do modelo)',
  CREATE_CHECKLIST: 'Criar nova lista de verificação',
  UPDATE_CHECKLIST: 'Lista de verificação de atualização',
  SAVE_CHECKLIST_SUBTITLE:
    'Salvar formulário de progresso da lista de verificação',
  CHECKLISTS_TITLE: 'Lista de verificação',
  MANAGE_TEMPLATE: 'Gerenciar',
  COPY_INSPECTION: 'Link de cópia',
  DUPLICATE: 'Duplicado',
  DUPLICATED: 'Duplicado com sucesso',
  NEW_UNIT_TITLE: 'Nova unidade',
  UPDATE_UNIT: 'Unidade de atualização',
  CREATE_UNIT: 'Criar nova unidade',
  UNIQUE_ID: 'ID Único',
  CREATE_UNIT_SUBTITLE:
    'As unidades são os alvos de cada inspeção, como ativos ou locais',
  SAVE_UNIT_SUBTITLE: 'Salvar unidade',
  NEW_BLANK_TEMPLATE: 'Novo modelo em branco',
  TEMPLATE_PAGE_TITLE: 'Modelos',
  PLATFORM_TEMPLATE_PAGE_TITLE: 'Modelos de plataforma',
  CLIENT_TEMPLATE_PAGE_TITLE: 'Galeria de modelos',
  UPDATE_TEMPLATE: 'Atualizar modelo',
  SAVE_TEMPLATE_SUBTITLE: 'Alterar o nome e a imagem do modelo',
  CREATE_TEMPLATE: 'Criar modelo',
  SELECT_TEMPLATE: 'Selecione o modelo',
  TEMPLATE_BUILDER: 'Construtor de modelos',
  MANAGE_CHECKLIST_ITEMS: 'Editar itens da lista de verificação',
  CREATE_BLANK: 'Criar em branco',
  CHECKLIST_DELETE_ALERT_TITLE:
    'Tem certeza de que deseja excluir esta lista de verificação?',
  CHECKLIST: 'Lista de controle',
  CREATE_CATEGORY: 'Crie uma categoria',
  UPDATE_CATEGORY: 'Atualizar uma categoria',
  PEOPLE: 'Pessoas',
  SELECT: 'Selecione....',
  LANGUAGE_pt: 'Português',
  LANGUAGE_en: 'Inglês',
  LANGUAGE_fr: 'Francês',
  LANGUAGE_de: 'Alemão',
  LANGUAGE_zh: 'chinês',
  LANGUAGE_es: 'Espanhol',
  LANGUAGE_DEFAULT: 'Padrão',
  Dashboard: 'Painel',
  Issues: 'Problemas',
  Organisation: 'Empresa',
  USERS: 'Usuários',
  Developer: 'Desenvolvedor',
  Webhooks: 'Ganchos da web',
  Subscription: 'Inscrição',
  MANAGE_UNITS: 'Gerencie suas unidades',
  ID: 'EU IA',
  LAST_REPORT_DATE: 'Último relatório',
  DELETE_WARNING: 'Você não poderá reverter após a exclusão',
  DELETE_CLIENT_WARNING: 'Você realmente deseja excluir este cliente',
  CREATE_CLIENT: 'Criar novo cliente',
  CREATE_CLIENT_DESCRIPTION:
    'Use esta janela de diálogo para adicionar um novo cliente',
  EMAIL: 'E-mail',
  IMAGE: 'Imagem',
  INVALID_FILE_TYPE: 'Você não pode fazer upload desses tipos de arquivo',
  CLIENT_PASSWORD_INFO: 'Use esta senha para fazer login',
  CREATE_CLIENT_BUTTON: 'Criar cliente',
  SIGN_OUT: 'sair',
  DEVELOPER_DOCUMENTATION: 'Documentação do desenvolvedor',
  REPORT_PROCESSING: 'O relatório ainda está sendo processado',
  OF: 'de',
  PAGINATION_INFO: 'Linhas por página',
  CANCEL: 'Cancelar',
  Client: 'Atribuído a',
  Client_here: 'Não atribuído',
  New_Category: 'Nova categoria',
  EDIT_CHECKLIST_ITEM_Title: 'Pergunta',
  CREATE_INSTRUCTION: 'Adicionar instruções',
  UPDATE_INSTRUCTION: 'Editar instruções',
  SAVE_INSTRUCTION_SUBTITLE:
    'A imagem e a descrição da instrução serão visíveis ao lado da pergunta para ajudar o usuário a fornecer uma resposta',
  ADD_ITEM: '+ Adicionar',
  TYPE_OF_RESPONSE: 'Tipo de resposta',
  Image: 'Imagem',
  EDIT_Instructions: 'Editar Instruções',
  ASSIGN_OWNER: 'Atribuir Proprietário',
  ASSIGN_OWNER_TO: 'Atribuir proprietário a',
  REMOVE_OWNER: 'Remover proprietário',
  OWNER_REMOVED: 'Proprietário removido',
  ASSIGNED_OWNER: 'Proprietário Atribuído',
  SHARE_CHECK: 'Compartilhar verificação',
  SHARE_CHECK_DESCRIPTION:
    'Use o formulário abaixo para compartilhá-lo com o verificador.',
  TEMPLATE_BUILDER_BREADCRUMB: 'Modelos',
  SEND_VIA: 'Enviar via',
  To: 'Para',
  Subject: 'Assunto',
  EMAIL_TOKEN_DESCRIPTION: 'Descrição',
  Share_Inspection: 'Compartilhar Inspeção',
  Open_Modal: 'Nova verificação',
  CHECKFIRST: 'Checkfirst',
  Select_unit: 'Selecionar unidade',
  SELECT_CHECKLIST: 'Selecionar lista de verificação',
  Checklists: 'Listas de verificação',
  Templates: 'Modelos',
  Email: 'Email',
  Add: 'Adicionar',
  Back: 'Voltar',
  Owner: 'Proprietário',
  Description: 'Descrição',
  FOOTER: 'Rodapé',
  Created: 'Criado',
  UPGRADE: 'Atualizar',
  READY_FOR_AN_UPGRADE: 'Pronto para uma atualização?',
  UPGRADE_ACCOUNT_TO_ACCESS_THEM:
    'Esses relatórios estão bloqueados devido a limites de uso em seu plano. Por favor,' +
    '\n' +
    'atualize sua conta para acessá-los.',
  ADD_LOGIC: 'Adicionar lógica',
  EDIT_CHECKLIST_ITEM: 'Instruções',
  VERIFY_WITH_AI: 'Verificar com IA',
  ADD_INSTRUCTIONS: 'Adicionar instruções',
  ADD_IMAGE: 'Adicionar imagem',
  CHECKLIST_BUILDER: {
    TITLE_PLACEHOLDER: 'Título',
    DESCRIPTION_PLACEHOLDER: 'Adicione uma descrição (opcional)',
    ADD_CRITERIA_BUTTON_LABEL: 'Adicionar critérios',
    ADD_SECTION_BUTTON_LABEL: 'Adicionar seção',
    SAVED_MESSAGE: 'Salvo com sucesso',
    BACK_BUTTON: 'Voltar',
    NEW_CHECK_BUTTON: 'Novo cheque',
    PREVIEW_BUTTON: 'Modo de visualização',
    USE_TEMPLATE_BUTTON: 'Usar modelo',
    FLOATING_TOOLBAR: {
      DUPLICATE: 'Duplicar seção',
      DELETE: 'Excluir seção',
      ADD_CRITERIA: 'Adicionar critério',
      ADD_SECTION: 'Adicionar seção'
    },
    SECTION: {
      TITLE_PLACEHOLDER: 'Escreva o título da seção aqui',
      DELETE_CONFIRMATION_MESSAGE:
        'Isso também excluirá todos os itens desta seção.',
      DELETE_TOOLTIP: 'Excluir',
      ERRORS: {
        REQUIRED: 'O campo título é obrigatório.',
        DUPLICATE:
          'O título não deve ser um dos seguintes valores: {{sections}}'
      }
    },
    CRITERIA: {
      TITLE_PLACEHOLDER: 'Adicionar critérios',
      TYPE_PLACEHOLDER: 'Tipo de resposta',
      DELETE_CONFIRMATION_MESSAGE:
        'Tem certeza de que deseja excluir este critério?',
      REQUIRED_LABEL: 'Obrigatório',
      CHANGE_TYPE_CONFIRMATION_MESSAGE:
        'Este critério contém critérios lógicos e aninhados. Isso será perdido se você alterar o tipo de critério. Gostaria de alterar o tipo de critério?',
      ERRORS: {
        REQUIRED: 'O campo título é obrigatório.',
        MIN: 'Insira pelo menos duas opções.',
        MAX: 'Insira menos de 100 opções.'
      }
    },
    LOGIC: {
      DAMAGED: 'Danificado',
      POOR: 'Pobre',
      FAIR: 'Justo',
      GOOD: 'Bom',
      GREAT: 'Ótimo',
      IF: 'Se a resposta',
      IF_IMAGE: 'Se imagem',
      THEN: 'Então',
      YES: 'Sim',
      NO: 'Não',
      NA: 'N/D',
      IS: 'É',
      IS_NOT: 'Não é',
      IS_EMPTY: 'Está vazia',
      IS_NOT_EMPTY: 'Não está vazio',
      ADD_CRITERIA: 'Mostrar critérios',
      FLAG_REPORT: 'Sinalizar o relatório',
      RAISE_ISSUE: 'Levantar um problema',
      EQUALS: 'Igual (=)',
      DOES_NOT_EQUAL: 'Diferente (≠)',
      GREATER_THAN: 'Maior que (>)',
      LESS_THAN: 'Menor que (<)',
      GREATER_THAN_OR_EQUAL_TO: 'Maior ou igual a (≥)',
      LESS_THAN_OR_EQUAL_TO: 'Menor ou igual a (≤)',
      BETWEEN: 'Entre',
      NOT_BETWEEN: 'Não entre',
      CONTAINS: 'Contém',
      DOES_NOT_CONTAIN: 'Não contém',
      STARTS_WITH: 'Começa com',
      ACTION_PLACEHOLDER: 'Selecionar ação',
      YES_NO_PLACEHOLDER: 'Selecionar múltiplos valores',
      DELETE_CONFIRMATION_MESSAGE:
        'Tem certeza de que deseja excluir esta lógica?'
    },
    INSTRUCTIONS: {
      PLACEHOLDER: 'Texto...',
      LABEL: 'Instruções',
      IMAGE_LABEL: 'Imagem'
    }
  },
  SaveBasicInformation: {
    ERRORS: {
      TITLE_REQUIRED: 'O campo de nome do dispositivo é obrigatório.',
      CLIENT_FIELD_REQUIRED: 'O campo de cliente é obrigatório.',
      FOOTER_LENGTH: 'O rodapé deve ter no máximo 630 caracteres'
    }
  },
  SHARE_INSPECTION_MODAL: {
    STEP_1: {
      TITLE: 'Selecionar checklist',
      SUBTITLE: 'Escolha o que deseja verificar na lista de checklists abaixo.',
      NAME: 'Nome',
      CREATED: 'Criado',
      NEW_CHECKLIST: 'Nova checklist'
    },
    STEP_2: {
      TITLE: 'Selecionar unidade',
      SUBTITLE: 'Selecione a unidade que deseja que seja verificada.',
      NAME: 'Nome',
      OWNER: 'Proprietário',
      CREATED: 'Criado',
      NEW_UNIT: 'Nova unidade'
    },
    STEP_3: {
      TITLE: 'Sucesso!',
      SUBTITLE:
        'O check {{id}} foi criado com sucesso. Use o formulário abaixo para compartilhá-lo com o verificador.',
      SEND_VIA: 'Enviar via',
      EMAIL: 'E-mail',
      LINK: 'Link',
      QR: 'Código QR',
      COPY: 'Copiar',
      CANCEL: 'Cancelar'
    }
  },
  CHECKS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Pré-visualizar',
      SHARE: 'Compartilhar'
    },
    HEADERS: {
      ID: 'ID',
      CHECKLIST: 'Lista de verificação',
      UNIT: 'Unidade',
      SCHEDULED: 'Agendado',
      DUE: 'Vencimento',
      DUE_DATE: 'Data de vencimento',
      ASSIGNED: 'Atribuído',
      ASSIGNED_TO: 'Atribuído a',
      STATUS: 'Estado'
    },
    STATUS: {
      OPEN: 'Aberto',
      STARTED: 'Iniciado',
      COMPLETED: 'Concluído',
      SCHEDULED: 'Agendado',
      CLOSED: 'Fechado'
    }
  },
  LOGO_ALT: 'Logo',
  CHECKLIST_ITEM_TYPE: {
    'Yes/No': 'Sim/Não',
    MEDIA: 'Mídia',
    TEXT: 'Texto',
    NUMBER: 'Número',
    RADIO_BUTTON: 'Botões de rádio',
    CHECK_BOX: 'Caixas de seleção',
    MULTIPLE_CHOICE: 'Escolha múltipla',
    DISCLAIMER: 'AVISO LEGAL',
    RESPONSE_TYPES: 'Tipos de resposta',
    CUSTOM_OPTIONS: 'Opções personalizadas',
    SIGNATURE: 'Assinatura',
    MULTI_MEDIA: 'Mídia',
    TIME: 'Tempo',
    AI: 'IA',
    CONDITION: 'Condição',
    DATE: 'Data',
    MULTIPLE_CHOICE_OPTIONS: {
      YES: 'Sim',
      NO: 'Não',
      NA: 'N/A',
      GOOD: 'Bom',
      FAIR: 'Justo',
      POOR: 'Ruim',
      CONFORM: 'Conforme',
      NOT_CONFORM: 'Não conforme',
      PENDING: 'Pendente',
      PLACEHOLDER: 'Título da opção'
    },
    MULTIPLE_CHOICE_MODAL: {
      TITLE: 'Opções de escolha múltipla',
      SUBTITLE: 'por exemplo: Sim, Não, N/A',
      OPTION: 'Opção',
      DELETE: 'Excluir',
      ADD_OPTION_BUTTON: 'Adicionar opção (ou pressione Enter)',
      SAVE_BUTTON: 'Salvar',
      CANCEL_BUTTON: 'Cancelar',
      MULTIPLE_SELECTION_TOGGLE: 'Seleção múltipla',
      SCORING_TOGGLE: 'Pontuação',
      SCORE: 'Pontuação'
    }
  },
  REPORTS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Visualizar',
      SHARE: 'Compartilhar'
    },
    HEADERS: {
      IMAGE_URL: 'URL da Imagem',
      RULE: 'Regra',
      IMAGE_CAPTION: 'Legenda da Imagem',
      WORDS_USED_TO_CHECK: 'Palavras Utilizadas para Verificar',
      DECISION: 'Decisão',
      VERIFIED: 'Verificado',
      VERIFICATION_PROMPT: 'Prompt de Verificação',
      ASSIGNED: 'Atribuído',
      ASSIGNED_TO: 'Atribuído a',
      STATUS: 'Estado'
    },
    STATUS: {
      OPEN: 'Aberto',
      STARTED: 'Iniciado',
      COMPLETED: 'Concluído',
      SCHEDULED: 'Agendado',
      CLOSED: 'Fechado'
    }
  },

  SHOW_MORE: '▼ Mostrar mais',
  SHOW_LESS: '▲ Mostrar menos',
  CONDITION_RESPONSES: {
    DAMAGED: 'Danificado',
    POOR: 'Pobre',
    FAIR: 'Justo',
    GOOD: 'Bom',
    GREAT: 'Ótimo'
  },
  SHOW_DETAILS: 'Mostrar detalhes',
  CHECKLISTS: {
    NAME: 'Nome',
    CREATED_BY: 'Criado por',
    LAST_MODIFIED: 'Última modificação',
    NEW_CHECKLIST: 'Nova checklist'
  },
  NO_CHECKS_CREATED: 'Nenhuma verificação foi criada',
  NO_REPORTS_CREATED: 'Nenhum relatório foi criado',
  NO_CHECKLIST_CREATED:
    'Nenhuma lista de verificação foi adicionada a esta unidade'
};

export default aeJSON;
