const enJSON = {
  AUTH_SIGN_UP_CARD_TITLE: 'Sign Up',
  AUTH_SIGN_UP_CARD_SUB_TITLE:
    'Fill in the fields below to sign up for an account.',
  AUTH_SIGN_UP_CARD_GOOGLE_BUTTON: 'Sign Up with Google',
  AUTH_SIGN_UP_CARD_OR_SEPARATOR: 'or',
  AUTH_SIGN_UP_CARD_NAME_FIELD_LABEL: 'Name',
  AUTH_SIGN_UP_CARD_NAME_FIELD_ERROR: 'The name field is required',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_LABEL: 'Email',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_REQUIRED: 'The email field is required',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_INVALID:
    'The email provided should be a valid email address',
  AUTH_SIGN_UP_CARD_PASS_FIELD_LABEL: 'Password',
  AUTH_SIGN_UP_CARD_PASS_FIELD_ERROR: 'The password field is required',
  AUTH_SIGN_UP_CARD_TERMS_AGREE_STATEMENT: 'I accept the',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS: 'terms and conditions',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS_ERROR:
    'You must agree to our terms and conditions',
  AUTH_SIGN_UP_CARD_BUTTON: 'Sign Up',
  AUTH_SIGN_UP_CARD_HAVE_ACCOUNT: 'Already have an account?',
  AUTH_SIGN_UP_CARD_LOGIN_LINK: 'Sign in here',
  AUTH_SIGN_UP_SIDEBAR_TITLE: 'Create digital inspection reports',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_1: 'Inspection management dashboard',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_2: 'Easy to use inspection app',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_3: 'Mobile-first digital inspection reports',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_4: 'Try 10 free reports per month',
  AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL: 'Email',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED: 'The email field is required',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID:
    'The email provided should be a valid email address',
  AUTH_LOGIN_CARD_PASS_FIELD_LABEL: 'Password',
  AUTH_LOGIN_CARD_PASS_FIELD_ERROR: 'The password field is required',
  AUTH_LOGIN_CARD_GOOGLE_BUTTON: 'Sign in with Google',
  AUTH_LOGIN_CARD_BUTTON: 'Sign in',
  AUTH_LOGIN_CARD_FORGOT_PASSWORD: 'Forgot Password?',
  AUTH_LOGIN_CARD_RESET_PASSWORD_LINK: 'Reset Password',
  AUTH_LOGIN_CARD_NO_ACCOUNT: "Don't have an account?",
  AUTH_LOGIN_CARD_REGISTER_LINK: 'Register',
  AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS: 'Incorrect email or password',
  USERS_LIST_PAGE_HEADER_TITLE: 'Users',
  USERS_LIST_PAGE_HEADER_CAPTION: 'Manage your Users.',
  USERS_LIST_EMPTY_MESSAGE: 'No Users',
  USERS_LIST_PAGE_HEADER_CREATE_BUTTON: 'Create New User',
  USERS_LIST_DELETE_USER_CONFIRMATION_MESSAGE:
    'Do you really want to delete this User?',
  USERS_LIST_DELETE_USER_BUTTON_TOOLTIP: 'Delete',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_TITLE: 'User Created',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_PASSWORD:
    'The user was created with password:',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL:
    'An invitation email has also been sent to the user on',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL_EXTENDED:
    ', they can use the link in that email to set their own password.',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_CAPTION: 'Use this password to login',
  USERS_LIST_CREATE_USER_DIALOG_TITLE: 'Create New User',
  USERS_LIST_CREATE_USER_DIALOG_CAPTION: 'Add a new user',
  USERS_LIST_CREATE_USER_DIALOG_CREATE_BUTTON: 'Create User',
  REPORT_DETAILS_HEADER_TITLE: 'Report Details',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'Download PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Download XLSX',
  REPORT_DETAILS_NO_ANSWER: 'No Answer',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Accountability',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'This section shows who performed the report',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Verification',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' All data verified with Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unit',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspection',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE: 'Are you sure?',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_SUBTITLE:
    'This will also delete any items in this category.',
  COPIED: 'Copied successfully ...',
  NO_ACTIVITY_MESSAGE: "Activity details aren't available yet...",
  COMMENT: 'COMMENT',
  configurations: 'Configurations',
  REPORT_HOOK: 'Report hook configurations',
  REPORT_PDF: 'Report PDF configurations',
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  new_configuration: 'new configuration',
  Key: 'Key',
  Title: 'Name',
  Actions: 'Actions',
  Clients: 'Clients',
  Value: 'Value',
  Edit: 'Edit',
  Cancel: 'Cancel',
  Save: 'Save',
  Saved: 'Saved',
  OPTIONS: 'Options',
  FORGET_PASSWORD: 'Forget Pssword',
  UPDATE_PASSWORD_TITLE: 'Update Password',
  CONFIRM_PASSWORD_VALIDATION:
    'Please enter the same password in the fields for Password and Confirm Password',
  PASSWORD_VALIDATION: 'The password field is required',
  TITLE_VALIDATION: 'The title field is required',
  PASSWORD_UPDATED: 'Password was updated successfully, Please try to login',
  ERROR: 'Something went wrong',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  UPDATE_PASSWORD: 'Update Password',
  UPDATE_USER: 'Update User',
  INVALID_EMAIL: 'Invalid Email',
  UPDATE_PASSWORD_TOGGEL: 'Update Password',
  NAME_VALIDATION: 'The name field is required',
  EMAIL_VALIDATION: 'The email field is required',
  MESSAGE_VALIDATION: 'The message field is required',
  SHOULD_VALID_EMAIL: 'The email provided should be a valid email address',
  IMAGE_VALIDATION: 'The image field is required',
  NAME_PLACEHOLDER: 'Name...',
  NAME_LABEL: 'Name',
  EMAIL_PLACEHOLDER: 'Email...',
  EMAIL_LABEL: 'Email',
  MESSAGE_LABEL: 'Message',
  CLIENT_IMAGE_LABEL: 'Image',
  PASSWORD_PLACEHOLDER: 'Password...',
  PASSWORD_LABEL: 'Password',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm Password...',
  CONFIRM_PASSWORD_LABEL: 'Confirm Password',
  SAVE_USER: 'Save User',
  UPDATE_PROFILE: 'Update Profile',
  UPDATE_PROFILE_DETAILS: 'Update your user profile',
  FLAGGED: 'Flagged',
  REPORT_DETAILS: 'Report Details',
  REMOVE_FLAG: 'Remove Flag',
  ADD_FLAG: 'Flag',
  CLIENT_REPORTS: 'Client reports',
  CLIENTS: 'Clients',
  REPORTS: 'Reports',
  CHECKS: 'Checks',
  UNITS: 'Units',
  DELETE: 'Delete',
  DELETED: 'Deleted Successfully',
  UNIT_DELETE_ALERT_TITLE: 'Are you sure you want to delete this unit?',
  NO: 'No',
  YES: 'Yes',
  NOT_SURE: 'Not Sure',
  FORGET_PASSWORD_Q: 'Forget password?',
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_TITLE: 'Recover Password',
  RESET_PASSWORD_SUB_TITLE: 'Enter your email to reset your password',
  UPDATE_PASSWORD_SUB_TITLE: 'Enter your password to update your password',
  SEND_NEW_PASSWORD: 'Send me a new password',
  TRY_SIGN: 'Want to try to sign in again?',
  CLICK_HERE: 'CLick here',
  PLATFORM_TEMPLATE_INFO:
    'Platform Templates cannot be modified until they are connected to a Unit',
  RESET_PASSWORD_INSTRUCTIONS:
    'The password reset instructions have been sent to your email',
  CHECK_EMAIL_INSTRUCTIONS: 'Check your email for further instructions',
  CONTINUE_LOGIN: 'Continue to login',
  TITLE: 'Name',
  REFERENCES: 'Inspections',
  REFERENCE: 'Inspection',
  GENERATE_REFERENCE: 'Create new Inspection',
  LINK: 'Link',
  NO_REPORTS: 'No reports created yet',
  FREE_TRIAL: 'Start your free trial today',
  EMAIL_VERIFICATION: 'Verify your email',
  SEND_EMAIL_TO: 'We have sent an email to',
  EMAIL_VERIFICATION_CONTINUE:
    'To continue, please check your email and click on the link to verify your account.',
  NOT_RECEIVE_EMAIL: "Didn't receive the email?",
  RESEND_EMAIL: 'Re-send the Email',
  EMAIL_VERIFICATION_RESEND_ERROR: 'Failed to send the email',
  TERMS_VALIDATION: 'You must agree to our terms and conditions',
  REGISTRATION_LAST_STEP: 'One more step and you’re done!',
  KNOW_MORE_INFO: 'Please let us know your company name',
  COMPANY_VALIDATION: 'The company field is required',
  COMPANY_LABEL: 'Company name',
  COMPANY: 'Company',
  SIGN_UP_HERE: 'Sign up',
  GETTING_START: 'Get Started',
  THANK_YOU: 'Thank You!',
  ON_BOARD: 'We are excited to get you on board.',
  UPDATE: 'Update',
  SAVED: 'Saved Successfully',
  SENT: 'Sent Successfully',
  UPDATE_COMPANY_HEADER: 'Company Details',
  UPDATE_COMPANY_INFO: 'You should set company name to continue',
  SEND_INSTRUCTIONS_EMAIL: 'Send',
  TOKEN_INSTRUCTION_EMAIL_TITLE: 'Send report instructions email',
  INSTRUCTION_EMAIL: 'Email',
  RESET_PASSWORD_SUCCESS:
    'Thanks, if the email address you entered is correct, you will be receiving an email shortly with instructions on how to reset your password',
  UPDATE_CLIENT: 'Company Details',
  UPDATE_CLIENT_PAGE_TITLE: "Use this page to update your company's details.",
  UPDATE_CLIENT_CARD_TITLE: 'Company Details',
  COMPANY_NAME_PLACEHOLDER: 'Update company name',
  COMPANY_NAME_LABEL: 'Company name',
  SAVE_CLIENT: 'Save Client',
  CHECKLIST_ITEM_TYPE: {
    'Yes/No': 'Yes/No',
    MEDIA: 'Media',
    TEXT: 'Text',
    NUMBER: 'Number',
    RADIO_BUTTON: 'Radio buttons',
    CHECK_BOX: 'Checkboxes',
    MULTIPLE_CHOICE: 'Multiple choice',
    DISCLAIMER: 'DISCLAIMER',
    RESPONSE_TYPES: 'Response types',
    CUSTOM_OPTIONS: 'Custom options',
    SIGNATURE: 'Signature',
    MULTI_MEDIA: 'Media',
    TIME: 'Time',
    AI: 'AI',
    CONDITION: 'Condition',
    DATE: 'Date',
    MULTIPLE_CHOICE_OPTIONS: {
      YES: 'Yes',
      NO: 'No',
      NA: 'N/A',
      GOOD: 'Good',
      FAIR: 'Fair',
      POOR: 'Poor',
      CONFORM: 'Conform',
      NOT_CONFORM: 'Not conform',
      PENDING: 'Pending',
      PLACEHOLDER: 'Option title'
    },
    MULTIPLE_CHOICE_MODAL: {
      TITLE: 'Multiple choice options',
      SUBTITLE: 'e.g. Yes, No, N/A',
      OPTION: 'Option',
      DELETE: 'Delete',
      ADD_OPTION_BUTTON: 'Add option (or hit enter)',
      SAVE_BUTTON: 'Save',
      CANCEL_BUTTON: 'Cancel',
      MULTIPLE_SELECTION_TOGGLE: 'Multiple selection',
      SCORING_TOGGLE: 'Scoring',
      SCORE: 'Score'
    }
  },
  SELECT_OPTIONS_HOLDER: 'separate entries with return key',
  SELECT_OPTIONS: 'Options',
  SELECT_OPTIONS_HELPER:
    'Please list the options in the text field; use Enter to separate between options',
  REGISTER_HTML_TITLE: 'Checkfirst - Sign up',
  FILTER: 'Filter',
  FLAGGED_all: 'Show all',
  FLAGGED_true: 'Flagged reports only',
  MANAGE_API_KEY_SUBHEADER:
    'Create API keys to give your application access to the Checkfirst API',
  API_KEY_MODAL_HEADER: 'Generate API Key',
  API_KEY_MODAL_SUBHEADER: 'Please enter a name to identify this key',
  CREATED_API_KEY_MODAL_HEADER: 'Key created',
  CREATED_API_KEY_MODAL_SUBHEADER:
    "Please store it in a safe place as it can't be recovered. Check the Developer Documentation to learn how to use it!",
  NAME: 'Name',
  DOWNLOAD_XLSX: 'Download Xlsx',
  SUBSCRIBE_TITLE: 'Choose your plan',
  SUBSCRIBE_BODY:
    'Simple, transparent pricing that grows with you. Try any plan free for 30 days.',
  SUBSCRIBE_BODY2: 'Annual pricing (save 15%)',
  SUBSCRIBE: 'Subscribe',
  PAYMENT_SUCCESS_BUTTON: 'Get started',
  TRY_AGAIN: 'Try Again',
  PAYMENT_SUCCESS_TITLE: 'Thank you',
  PAYMENT_SUCCESS_BODY:
    'Your information was saved successfully. We are excited to get you on board!',
  PAYMENT_FAILURE_TITLE: 'Something went wrong',
  PAYMENT_FAILURE_BODY:
    'Your payment may be failed because of your card balance or something else please contact customer service for more clarification',
  SUBSCRIPTION_TITLE: 'Manage subscriptions',
  SUBSCRIPTION_DESCRIPTION: 'MANAGE YOUR SUBSCRIPTION',
  SUBSCRIPTION_CARD_HEADER: 'Subscription information',
  SUBSCRIPTION_CARD_SUB_HEADER:
    'Manage details related to your associated email addresses',
  SUBSCRIPTION_PLAN: 'Plan',
  SUBSCRIPTION_CARD: 'Credit Card',
  SUBSCRIPTION_BILLED_WITH: 'Billed with Mastercard •••• ',
  MANAGE_SUBSCRIPTION: 'Manage subscription',
  SUBSCRIPTION_ACTIVE: 'Active',
  SUBSCRIPTION_IN_ACTIVE: 'InActive',
  SUBSCRIPTION_END: 'END DATE',
  PRICING_PLANS: 'Pricing plans',
  CURRENCY: '€',
  MONTH: 'mth',
  YEAR: 'yr',
  ENTERPRISE_TITLE: 'Get in touch',
  ENTERPRISE_NAME: 'Platinum',
  ENTERPRISE_DESCRIPTION: 'Created for enterprise customers',
  ENTERPRISE_FEATURE1: 'Unlimited units',
  ENTERPRISE_FEATURE2: 'Single sign-on',
  ENTERPRISE_FEATURE3: 'High availability SLA',
  ENTERPRISE_FEATURE4: 'Bespoke integrations',
  ENTERPRISE_FEATURE5: 'Onboarding support & training',
  ENTERPRISE_FEATURE6: 'All Gold features',
  EDIT_ITEM: 'Update',
  UPDATE_CHECKLIST_ITEM: 'Update checklist items',
  EDIT: 'Edit',
  EDIT_CHECKLIST_ITEM: 'Instructions',
  N0_CLIENTS_ACTIVITIES: 'No Clients Activities',
  CREATION_DATE: 'Creation Date',
  ACTIVITY_ACTION: 'Action',
  ACTIVITY_MODULE: 'Module',
  CLIENT_NAME: 'Name',
  CLIENT_LABEL: 'Client',
  CLIENT_ACTIVITY_SEARCH_PLACEHOLDER: 'Select Client',
  CLIENT_ACTIVITY_TITLE: 'Clients Activities',
  UPLOAD: 'Select an image to upload',
  BRONZE: 'Bronze',
  GOLD: 'Gold',
  ENTERPRISE: 'Enterprise',
  NONE: 'None',
  HISTORY: 'History',
  CHECKLIST_ITEM_DRILL_DOWN_DESCRIPTION:
    'Below is the history of all inspections for this unit. Click on each date to load the corresponding report.',
  REPORTS_TO_DATE: 'Reports to date',
  FLAGGED_REPORTS: 'Flagged Reports',
  ACTIVE_UNITS: 'Active Units',
  RECENT_REPORTS: 'Reports',
  RECENT_UNITS: 'Recently added units',
  INSPECTOR: 'Inspector',
  DATE: 'Date',
  REPORTS_COUNT: 'Reports',
  UNIT: 'Unit',
  VIEW_ALL: 'View all',
  ISSUE_PRIORITY: 'Issue Priority',
  ISSUE_TITLE: 'Issue Priority',
  ISSUE_DESCRIPTION: 'Description',
  ISSUE_ID: 'ID',
  ISSUE_REPORTED: 'Reported',
  ISSUES: 'Flagged',
  ISSUE_GALLERY: 'Gallery',
  ISSUE_DETAILS: 'Issue Details',
  SHARING_REPORT: 'Share',
  LOGGED_IN_FIRST: 'You must be logged in',
  WELCOME_MESSAGE: 'Welcome, ',
  YOUR_STATISTICS_MESSAGE: 'Your statistics for today, ',
  ISSUES_HEADER: 'Flagged',
  ISSUES_SUBHEADER: 'Manage issues found during inspection',
  ISSUES_PRIORITY: 'PRIORITY',
  UPPERCASE_TITLE: 'TITLE',
  STATUS: 'STATUS',
  UPPERCASE_UNIT: 'UNIT',
  DESCRIPTION: 'DESCRIPTION',
  IMAGES: 'IMAGES',
  REPORTED: 'REPORTED',
  DEACTIVATED: 'Deactivated',
  DEACTIVATE: 'Deactivate',
  ACTIVATE: 'Activate',
  SHOW_ACTIVE_CLIENTS: 'Show active clients',
  SHOW_DEACTIVATED_CLIENTS: 'Show deactivated clients',
  CLIENT_USERS: 'Client users',
  CO_SIGN: 'Co-sign',
  CO_SIGN_HERE: 'Co-sign here',
  SIGN_MODAL_TITLE: 'Co-sign Report',
  SIGN_MODAL_DESCRIPTION:
    'Use this dialog window to co-sign the inspection report.',
  NAME_HOLDER: 'Add name',
  NAME_REQUIRED: 'The name field is required',
  SIGNATURE_IMAGE_URL: 'Signature',
  SIGNATURE_REQUIRED: 'The signature field is required',
  SIGN_BUTTON: 'Submit',
  UPDATE_PASSWORD_INFO: 'Do you want update you password?',
  UPDATE_PASSWORD_LINK: 'Update password',
  UPDATE_USER_PASSWORD: 'Change Password',
  UPDATE_USER_PASSWORD_CARD_TITLE: 'User Information',
  OLD_PASSWORD_LABEL: 'Current Password',
  SAVE_USER_PASSWORD: 'Save',
  OLD_PASSWORD_PLACEHOLDER: 'Current Password...',
  UPDATE_PASSWORD_DESCRIPTION: 'Enter your old password to set a new one. ',
  COPY_REPORT_TOKEN: 'Copy report token',
  QR_CODE: 'QR code',
  QR_CODE_MODAL_TITLE: 'Inspection Link QR',
  DOWNLOAD: 'Download',
  DOWNLOADED: 'Downloaded Successfully',
  DEVELOPMENT: 'Development',
  UNIT_FORM_DESCRIPTION_PLACEHOLDER: 'Enter a description here.',
  UNIT_FORM_TITLE_PLACEHOLDER: 'Name.',
  UNIT_FORM_DESCRIPTION_CAPTION:
    'This information will be seen by the end user when they start the report, so make sure that this description helps them create the report in the way that is best for your business.',
  CREATE_USER_NAME_PLACEHOLDER: 'Enter the name here',
  CREATE_USER_EMAIL_PLACEHOLDER: 'Enter the email here',
  SAVE: 'Add',
  CREATE: 'Create',
  UPLOAD_CLIENT_LOGO: 'Logo',
  COPY: 'Copy',
  ROLE: 'Role',
  ROLE_LABEL: 'Select user Role',
  ROLE_client: 'Client Admin',
  ROLE_client_manager: 'Client Manager',
  ROLE_client_contributor: 'Client Contributor',
  USERS_LIST_EDIT_USER_BUTTON_TOOLTIP: 'Update User',
  USERS_LIST_UPDATE_USER_DIALOG_TITLE: 'Update User Information',
  USERS_LIST_UPDATE_USER_DIALOG_CAPTION: 'Update User',
  USERS_LIST_UPDATE_USER_DIALOG_CREATE_BUTTON: 'Update user',
  WEBHOOK_SCREEN_LIST_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_LIST_CAPTION:
    'Send reports, issues and approvals back to your application.',
  WEBHOOK_SCREEN_LIST_CREATE_BUTTON: 'Add webhook',
  REPORT_CREATED: 'Report',
  ISSUE_CREATED: 'Issue',
  COUNTER_SIGNATURE_CREATED: 'Counter signature',
  TYPE: 'TYPE',
  URL: 'URL',
  MODIFIED: 'MODIFIED',
  NO_WEBHOOKS_ADDED: 'No webhooks added yet.',
  ACTION: 'ACTION',
  EDIT_WEBHOOK: 'Edit',
  WEBHOOK_DETAILS: 'Details',
  WEBHOOK_SCREEN_SAVE_TITLE: 'Add Webhook',
  WEBHOOK_SCREEN_SAVE_CAPTION:
    'Select which type of event you would like to be notified about and then add the URL for your webhook.',
  HOOK_TYPE_REQUIRED: 'Type field is required',
  HOOK_URL_REQUIRED: 'URL field is required',
  ADD_WEBHOOK: 'Add Webhook',
  WEBHOOK_TYPE_LABEL: 'Type',
  WEBHOOK_TYPE_PLACEHOLDER: 'Type',
  WEBHOOK_URL_PLACEHOLDER: 'URL',
  WEBHOOK_URL_LABEL: 'URL',
  WEBHOOK_SCREEN_DETAILS_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_DETAILS_CAPTION: 'Organisation / Webhooks',
  HOOK_DATE: 'DATE',
  WEBHOOK_RETRY: 'Retry',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  REQUEST_DETAIL_MODAL_HEADER: 'Request details',
  DETAILS: 'Details',
  RESPONSE: 'RESPONSE',
  BODY: 'Body',
  ACTIONS: 'Actions',
  INVALID_MAGIC_LINK: 'The magic link maybe invalid or expired',
  URL_VALIDATION: 'Please enter valid URL',
  UNIT_FORM_REDIRECT_URL_PLACEHOLDER: 'Enter a inspection redirect URL here.',
  UNIT_FORM_REDIRECT_URL_LABEL: 'Enter a inspection redirect URL here.',
  ID_NAME: 'ID',
  CREATED_AT: 'Created',
  OPEN: 'Open',
  NEW_CHECKLIST_TITLE: 'New Checklist',
  UNTITLED_CHECKLIST: 'Untitled checklist',
  UNTITLED_CHECKLIST_FROM_TEMPLATE: 'Untitled checklist(from template)',
  CREATE_CHECKLIST: 'New blank checklist',
  UPDATE_CHECKLIST: 'Update  Checklist',
  SAVE_CHECKLIST_SUBTITLE:
    'Enter a name for your checklist, you can also add a description and image',
  CHECKLISTS_TITLE: 'Checklists',
  MANAGE_TEMPLATE: 'Manage',
  COPY_INSPECTION: 'Copy link',
  DUPLICATE: 'Duplicate',
  DUPLICATED: 'Duplicated successfully',
  NEW_UNIT_TITLE: 'New unit',
  UPDATE_UNIT: 'Update Unit',
  CREATE_UNIT: 'Create new unit',
  UNIQUE_ID: 'Unique ID',
  CREATE_UNIT_SUBTITLE:
    'Units are the targets of each inspection, such as assets or locations',
  SAVE_UNIT_SUBTITLE: 'Save Unit',
  NEW_BLANK_TEMPLATE: 'New Blank Template',
  TEMPLATE_PAGE_TITLE: 'Checklists',
  DASHBOARD_PAGE_DESCRIPTION: 'The dashboard shows a summary of your activity.',
  CLIENT_PAGE_DESCRIPTION: 'List of clients.',
  TEMPLATE_PAGE_DESCRIPTION:
    'Templates are you chosen checklists. Checkfirst also has a library of platform templates.',
  CHECKS_DESCRIPTION:
    'Checks are tasks to complete a checklist. Track the progress of the checks here.',
  REPORTS_DESCRIPTION:
    'Reports are made when the checklist is completed. They are verified with time-stamps, geo-location and digital signatures.',
  UNITS_DESCRIPTION:
    'Units are what you want to check, such as assets or locations. Units let you organise reports to show historic data.',
  ISSUES_DESCRIPTION:
    'Issues are problems or concerns reported a check. Reports with issues get automatically flagged.',
  COMPANY_DETAILS_DESCRIPTION:
    "Use this page to update your company's details.",
  COMPANY_WEBHOOKS_DESCRIPTION:
    'Send reports, issues and approvals back to your application',
  PLATFORM_TEMPLATE_PAGE_TITLE: 'Platform Templates',
  CLIENT_TEMPLATE_PAGE_TITLE: 'Template Gallery',
  UPDATE_TEMPLATE: 'Update Template',
  SAVE_TEMPLATE_SUBTITLE: 'Change name and image for the template',
  CREATE_TEMPLATE: 'Create Template',
  SELECT_TEMPLATE: 'From a template',
  TEMPLATE_BUILDER: 'Template Builder',
  MANAGE_CHECKLIST_ITEMS: 'Edit Checklist Items',
  CREATE_BLANK: 'Blank checklist',
  CHECKLIST_DELETE_ALERT_TITLE:
    'Are you sure you want to delete this checklist?',
  CHECKLIST: 'Checklist',
  CREATE_CATEGORY: 'Create a category',
  UPDATE_CATEGORY: 'Update a category',
  PEOPLE: 'Assigned owner',
  SELECT: 'Select....',
  LANGUAGE_pt: 'Portuguese',
  LANGUAGE_en: 'English',
  LANGUAGE_fr: 'French',
  LANGUAGE_de: 'German',
  LANGUAGE_zh: 'Chinese',
  LANGUAGE_es: 'Spanish',
  LANGUAGE_DEFAULT: 'Default',
  Dashboard: 'Dashboard',
  Issues: 'Issues',
  Organisation: 'Company',
  USERS: 'Users',
  Developer: 'Developer',
  Webhooks: 'Webhooks',
  Subscription: 'Subscription',
  MANAGE_UNITS: 'Manage your units',
  ID: 'ID',
  LAST_REPORT_DATE: 'Last Report',
  DELETE_WARNING: "You won't be able to revert after deletion",
  DELETE_CLIENT_WARNING: 'Do you really want to delete this client',
  CREATE_CLIENT: 'Create new client',
  CREATE_CLIENT_DESCRIPTION: 'Use this dialog window to add a new client',
  EMAIL: 'Email',
  IMAGE: 'Image',
  INVALID_FILE_TYPE: 'You cannot upload these file types',
  CLIENT_PASSWORD_INFO: 'Use this password to login',
  CREATE_CLIENT_BUTTON: 'Create Client',
  SIGN_OUT: 'Sign out',
  DEVELOPER_DOCUMENTATION: 'Developer Documentation',
  REPORT_PROCESSING: 'The report is still processing',
  OF: 'of',
  PAGINATION_INFO: 'Rows per page',
  'Unit Title': 'Name',
  'Select Unit Image': 'Image',
  CANCEL: ' Cancel',
  Client: 'Assigned to',
  Client_here: 'Unassigned',
  New_Category: 'New category',
  EDIT_CHECKLIST_ITEM_Title: 'Question',
  CREATE_INSTRUCTION: 'Add instructions',
  UPDATE_INSTRUCTION: 'Edit Instructions',
  SAVE_INSTRUCTION_SUBTITLE:
    'The instruction image and description will be visible alongside the question to help guide the user on how to provide an answer',
  ADD_ITEM: '+  Add',
  TYPE_OF_RESPONSE: 'Type of response',
  Image: 'Image',
  EDIT_Instructions: 'Edit Instructions',
  ASSIGN_OWNER: 'Assign Owner',
  ASSIGN_OWNER_TO: 'Assign Owner to',
  REMOVE_OWNER: 'Remove Owner',
  OWNER_REMOVED: 'Owner Removed',
  ASSIGNED_OWNER: 'Assigned owner',
  SHARE_CHECK: 'Share check',
  SHARE_CHECK_DESCRIPTION: 'Use the form below to share it with the checker.',
  TEMPLATE_BUILDER_BREADCRUMB: 'Templates',
  SEND_VIA: 'Send via',
  To: 'To',
  Subject: 'Subject',
  EMAIL_TOKEN_DESCRIPTION: 'Description',
  Share_Inspection: 'Share Inspection',
  Open_Modal: 'New check',
  CHECKFIRST: 'Checkfirst',
  Select_unit: 'Select unit',
  SELECT_CHECKLIST: 'Select checklist',
  Checklists: 'Checklists',
  Templates: 'Templates',
  Email: 'Email',
  Add: 'Add',
  Back: 'Back',
  Owner: 'Owner',
  Descirption: 'Description',
  FOOTER: 'Footer',
  Created: 'Created',
  UPGRADE: 'Upgrade',
  YOU_HAVE: 'You have ',
  Report_is_locked: 'Report is locked',
  READY_FOR_AN_UPGRADE: 'Ready for an upgrade?',
  REPORTS_LEFT_THIS_MONTH: 'reports left this month.',
  FOR_UNLIMITED_ACCESS: 'for unlimited access.',
  free: 'free',
  UPGRADE_ACCOUNT_TO_ACCESS_THEM:
    'These reports are locked due to usage limits in your plan. Please' +
    '\n' +
    ' upgrade your account to access them.',
  ADD_LOGIC: 'Add logic',
  ADD_INSTRUCTIONS: 'Add instructions',
  ADD_IMAGE: 'Add image',
  VERIFY_WITH_AI: 'Verify with AI',
  CHECKLIST_BUILDER: {
    TITLE_PLACEHOLDER: 'Title',
    DESCRIPTION_PLACEHOLDER: 'Add description (optional)',
    ADD_CRITERIA_BUTTON_LABEL: 'Add Criteria',
    ADD_SECTION_BUTTON_LABEL: 'Add Section',
    SAVED_MESSAGE: 'Saved Successfully',
    BACK_BUTTON: 'Back',
    NEW_CHECK_BUTTON: 'New check',
    PREVIEW_BUTTON: 'Preview mode',
    USE_TEMPLATE_BUTTON: 'Use template',
    FLOATING_TOOLBAR: {
      DUPLICATE: 'Duplicate section',
      DELETE: 'Delete section',
      ADD_CRITERIA: 'Add criteria',
      ADD_SECTION: 'Add section'
    },
    SECTION: {
      TITLE_PLACEHOLDER: 'Write section title here',
      DELETE_CONFIRMATION_MESSAGE:
        'This will also delete any items in this section.',
      DELETE_TOOLTIP: 'Delete',
      ERRORS: {
        REQUIRED: 'The title field is required.',
        DUPLICATE:
          'The title must not be one of the following values: {{sections}}'
      }
    },
    CRITERIA: {
      TITLE_PLACEHOLDER: 'Add criteria',
      TYPE_PLACEHOLDER: 'Type of response',
      DELETE_CONFIRMATION_MESSAGE:
        'Are you sure you want to delete this criteria?',
      REQUIRED_LABEL: 'Required',
      CHANGE_TYPE_CONFIRMATION_MESSAGE:
        'This criteria contains logic and nested criteria. This will be lost if you change the criteria type. Would you like to change the criteria type?',
      ERRORS: {
        REQUIRED: 'The title field is required.',
        MIN: 'Please enter at least 2 options.',
        MAX: 'Please enter less than 100 options.'
      }
    },
    LOGIC: {
      DAMAGED: 'Damaged',
      POOR: 'Poor',
      FAIR: 'Fair',
      GOOD: 'Good',
      GREAT: 'Great',
      IF: 'If answer',
      IF_IMAGE: 'If image',
      THEN: 'Then',
      SCORE: 'Score',

      YES: 'Yes',
      NO: 'No',
      NA: 'N/A',
      IS: 'Is',
      IS_NOT: 'Is not',
      IS_EMPTY: 'Is empty',
      IS_NOT_EMPTY: 'Is not empty',
      ADD_CRITERIA: 'Show criteria',
      FLAG_REPORT: 'Flag the report',
      SCORING: 'Add a score',
      RAISE_ISSUE: 'Raise an issue',
      EQUALS: 'Equals (=)',
      DOES_NOT_EQUAL: 'Does not equal (≠)',
      GREATER_THAN: 'Greater than (>)',
      LESS_THAN: 'Less than (<)',
      GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to (≥)',
      LESS_THAN_OR_EQUAL_TO: 'Less than or equal to (≤)',
      BETWEEN: 'Between',
      NOT_BETWEEN: 'Not between',
      CONTAINS: 'Contains',
      DOES_NOT_CONTAIN: 'Does not contain',
      STARTS_WITH: 'Starts with',
      ACTION_PLACEHOLDER: 'Select action',
      YES_NO_PLACEHOLDER: 'Select multiple values',
      DELETE_CONFIRMATION_MESSAGE:
        'Are you sure you want to delete this logic ?'
    },
    INSTRUCTIONS: {
      PLACEHOLDER: 'Text...',
      LABEL: 'Instructions',
      IMAGE_LABEL: 'Image'
    }
  },
  SaveBasicInformation: {
    ERRORS: {
      TITLE_REQUIRED: 'Unit name field is required.',
      CLIENT_FIELD_REQUIRED: 'The client field is required.',
      FOOTER_LENGTH: 'Footer must be at most 630 characters'
    }
  },
  SHARE_INSPECTION_MODAL: {
    STEP_1: {
      TITLE: 'Select checklist',
      SUBTITLE:
        'Choose what you want to check from the list of checklists below.',
      NAME: 'Name',
      CREATED: 'Created',
      NEW_CHECKLIST: 'New checklist'
    },
    STEP_2: {
      TITLE: 'Select unit',
      SUBTITLE: 'Select the unit that you want to be checked.',
      NAME: 'Name',
      OWNER: 'Owner',
      CREATED: 'Created',
      NEW_UNIT: 'New unit'
    },
    STEP_3: {
      TITLE: 'Success!',
      SUBTITLE:
        'Check {{id}} was created successfully. Use the form below to share it with the checker.',
      SEND_VIA: 'Send via',
      EMAIL: 'Email',
      LINK: 'Link',
      QR: 'QR code',
      COPY: 'Copy',
      CANCEL: 'Cancel'
    }
  },
  LOGO_ALT: 'Logo',
  CHECKS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Preview',
      SHARE: 'Share'
    },
    HEADERS: {
      ID: 'ID',
      CHECKLIST: 'Checklist',
      UNIT: 'Unit',
      SCHEDULED: 'Scheduled',
      DUE: 'Due',
      DUE_DATE: 'Due date',
      ASSIGNED: 'Assigned',
      ASSIGNED_TO: 'Assigned to',
      STATUS: 'Status'
    },
    STATUS: {
      OPEN: 'Open',
      STARTED: 'Started',
      COMPLETED: 'Completed',
      SCHEDULED: 'Scheduled',
      CLOSED: 'Closed'
    }
  },
  REPORTS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Preview',
      SHARE: 'Share'
    },
    HEADERS: {
      IMAGE_URL: 'Image URL',
      RULE: 'Rule',
      IMAGE_CAPTION: 'Image caption',
      WORDS_USED_TO_CHECK: 'Words used to check',
      DECISION: 'Decision',
      VERIFIED: 'Verified',
      VERIFICATION_PROMPT: 'Verification prompt',
      ASSIGNED: 'Assigned',
      ASSIGNED_TO: 'Assigned to',
      STATUS: 'Status'
    },
    STATUS: {
      OPEN: 'Open',
      STARTED: 'Started',
      COMPLETED: 'Completed',
      SCHEDULED: 'Scheduled',
      CLOSED: 'Closed'
    }
  },
  SHOW_MORE: '▼ Show more',
  SHOW_LESS: '▲ Show less',
  SHOW_DETAILS: 'Show details',
  CONDITION_RESPONSES: {
    DAMAGED: 'Damaged',
    POOR: 'Poor',
    FAIR: 'Fair',
    GOOD: 'Good',
    GREAT: 'Great'
  },
  CHECKLISTS: {
    NAME: 'Name',
    CREATED_BY: 'Created by',
    LAST_MODIFIED: 'Last modified',
    NEW_CHECKLIST: 'New checklist'
  },
  NO_CHECKS_CREATED: 'No checks have been created',
  NO_REPORTS_CREATED: 'No reports have been created',
  NO_CHECKLIST_CREATED: 'No checklists have been added to this unit'
};

export default enJSON;
