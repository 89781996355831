import { REPORT_CONFIG_TYPES } from 'src/constants/report-config';
import { SortType } from './sort';
import { Token } from './token';
import { ChecklistItemType, IsFlagged, Unit } from './unit';
import { MediaItem } from '../stores/backend/checks/types';
import { SignatureUser } from 'src/content/dashboards/Users/types';
import { REPORT_PROCESS_JOBS } from 'src/constants/report-processes';

export interface ReportCategory {
  items: ReportChecklistItem[];
  maxScore?: number;
  actualScore?: number;
}

export interface ReportChecklistItem {
  createdAt: string;
  updatedAt: string;
  id: number;
  description: string;
  categoryName: string;
  title: string;
  checklistItem: {
    createdAt: string;
    updatedAt: string;
    id: number;
    title: string;
    order: number;
    type: string;
    mandatory: boolean;
    isDeleted: boolean;
    imageURL: string | null;
    instruction: string | null;
    checklistItemOptions: any[]; // Change this type as per your actual data structure
    checklistItemCategory: {
      createdAt: string;
      updatedAt: string;
      id: number;
      name: string;
      order: number;
      isDeleted: boolean;
    };
    categoryName: string;
    categoryId: number;
    categoryOrder: number;
  };
  condition: any; // Change this type as per your actual data structure
  media: MediaItem[];
  texts: any[]; // Change this type as per your actual data structure
  numbers: any[]; // Change this type as per your actual data structure
  questions: any[]; // Change this type as per your actual data structure
  selectOptions: any[]; // Change this type as per your actual data structure
  type: ChecklistItemType;
  actualScore?: number;
  maxScore?: number;
  allowMultipleOptions?: boolean;
  flagged?: boolean;
  reportedLogics: any[];
}

export interface Report {
  id: string;
  createAt: number;
  reportChecklistItems?: ReportChecklistItem[];
  unitChecklist: {
    title: string;
    id: number;
    unit: Unit;
    isDeleted: boolean;
    isTemplate?: boolean;
  };
  unit: Unit;
  signatures: Signature[];
  reportCreatorsInformation?: ReportCreatorInfo[];
  sharingTokens: ReportSharingToken[];
  isLocked: boolean;
  createdAt: number;
  reportConfigs: ReportConfig[];
  reportProcesses: ReportProcesses[];
  reportSummary: string;
  reportScoringSummary?: ReportScoringSummary;
}

export interface CategorySummaryDto {
  categoryId: number;
  categoryName: string;
  obtainedScore: number;
  possibleScore: number;
  scorePercentage: number;
}

export interface ReportScoringSummary {
  items: CategorySummaryDto[];
}

export interface ReportConfig {
  configKey: REPORT_CONFIG_TYPES;
  configValue: string | number;
}

export interface ReportProcesses {
  processKey: REPORT_PROCESS_JOBS;
  processValue: boolean;
}

export interface ReportSharingToken {
  token: Token;
}

export interface ReportRow {
  reportId: number;
  unitId: number;
  companyName: string;
  unitTitle: string;
  unitDescription: string;
  isFlagged: IsFlagged;
  createdAt: string;
  signatureName: string;
  unitChecklistId: number;
  unitChecklistTitle: number;
  isLocked: boolean;
  reportChecklistItems?: {
    createdAt: string;
    updatedAt: string;
    id: number;
    description: string;
    categoryName: string;
    title: string;
    checklistItem: {
      createdAt: string;
      updatedAt: string;
      id: number;
      title: string;
      order: number;
      type: string;
      mandatory: boolean;
      isDeleted: boolean;
      imageURL: string | null;
      instruction: string | null;
      checklistItemOptions: any[];
      checklistItemCategory: {
        createdAt: string;
        updatedAt: string;
        id: number;
        name: string;
        order: number;
        isDeleted: boolean;
      };
      categoryName: string;
      categoryId: number;
      categoryOrder: number;
    };
    condition: any;
    media: MediaItem[];
    texts: any[];
    numbers: any[];
    questions: any[];
    selectOptions: any[];
  }[];
  id?: number;
}

export enum ReportSortKey {
  signatureName = 'signature.name',
  title = 'unit.title',
  checklistTitle = 'unitChecklist.title',
  createdAt = 'report.createdAt',
  id = 'report.id',
  flagged = 'isFlagged',
  companyName = 'client.name'
}

export interface ReportSort {
  key: ReportSortKey;
  sort: SortType;
}

export interface NumberQuestion {
  id?: number;
  title: string;
  value: number;
}

export interface SelectOption {
  id: number;
  value: string;
  selected: boolean;
}

export interface ChecklistItemOption {
  id: number;
  title: string;
  isDeleted: boolean;
  order: number;
}

export interface TextQuestion {
  id: number;
  title: string;
  value: string;
}

export interface Condition {
  id: string;
  name: string;
}

/**@deprecated use MediaItem instead */
export interface Media {
  id: string;
  type: string;
  uri: string;
  timeStamp: string;
}

export interface Question {
  id: string;
  title: string;
  value: number;
  comment: string;
}

export enum SignatureType {
  USER = 'USER',
  CLIENT = 'CLIENT'
}

export interface Signature {
  id: string;
  name: string;
  date: string;
  imageURL: string;
  type: SignatureType;
  user: SignatureUser;
}

export interface ReportRow {
  reportId: number;
  unitTitle: string;
  unitDescription: string;
  isFlagged: IsFlagged;
  clientId: number;
  clientName: string;
  isLocked: boolean;
}

export interface ReportCreatorInfo {
  isMobile: boolean;
  isDesktop: boolean;
  isBot: boolean;
  os: string;
  platform: string;
  browser: string;
  ip: string;
  latitude: number;
  longitude: number;
  deviceMake: string;
  deviceModel: string;
  deviceVersion: string;
}

export interface ReportWithCreator extends Report, ReportCreatorInfo {}

export const EmptyReport: Report = {
  id: '',
  createAt: 1234567,
  createdAt: 1234567,
  reportChecklistItems: [],
  unitChecklist: {
    id: 0,
    title: '',
    unit: {
      id: '',
      title: '',
      description: '',
      imageURL: '',
      client: null,
      checklistItems: [],
      unitCategories: []
    },
    isDeleted: false
  },
  unit: {
    id: '',
    title: '',
    description: '',
    imageURL: '',
    client: null,
    checklistItems: [],
    unitCategories: []
  },
  signatures: [],
  reportCreatorsInformation: [],
  reportConfigs: [],
  reportProcesses: [],
  reportSummary: '',
  sharingTokens: [],
  isLocked: false
};

export const EmptyReportCreatorInfo: ReportCreatorInfo = {
  ip: '',
  browser: '',
  isBot: false,
  isDesktop: false,
  isMobile: false,
  os: '',
  platform: '',
  deviceMake: '',
  deviceModel: '',
  deviceVersion: '',
  latitude: 0,
  longitude: 0
};
